<template>
    <el-breadcrumb separator-class="el-icon-arrow-right" class="my-breadcrumb">
        <el-breadcrumb-item v-for="item in pathList" :key="item.path">
            <router-link class="routerLink" :to="item.redirect || item.path">{{item.meta.title}}</router-link>
        </el-breadcrumb-item>
    </el-breadcrumb>
</template>
<script>
import {
    // getCurrentInstance,
    reactive,
    toRefs
} from 'vue'
import {useRouter} from 'vue-router'
export default {
    name: 'breadcrumb',
    setup() {
        const state = reactive({
            pathList: []
        })
        const router = useRouter()
        const matched = router.currentRoute.value.matched
        if(matched[0] && matched[0].meta.title !== '首页') {
            state.pathList = [{path: '/', meta: {title: '首页'}}].concat(matched)
        } else {
            state.pathList = matched
        }

        return {
            ...toRefs(state),
        }
    }
}
</script>
<style lang="scss" scoped>
    @import 'index';
</style>