<template>
    <div class="wrap">
        <div class="content-container">
            <Breadcrumb class="breadcrumb" />
            <section class="news-content" v-if="!!article">
                <div class="header">
                    <div class="title">{{article.title}}</div>
                    <div class="tips">
                        <span v-if="article.cate">分类：{{article.cate}}</span>
                        <span v-if="article.source">来源：{{article.source}}</span>
                        <span>作者：{{article.author}}</span>
                        <span>发布时间：{{article.publish_at}}</span>
                        <span>阅读量：{{article.visits}}</span>
                    </div>
                    <div v-if="article.summary" class="abstract">
                        {{article.summary}}
                    </div>
                    <div class="content ql-editor" v-html="article.content"></div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import {
    getCurrentInstance,
    reactive,
    toRefs
} from 'vue'
import { useRoute } from 'vue-router'
import Breadcrumb from '@/components/Breadcrumb'
export default {
    name: 'news-detail',
    components: {
        Breadcrumb
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const route = useRoute()
        const state = reactive({
            article: null
        })

        let articleId = route.query.id
        proxy.$api.article.getArticle(articleId).then(res => {
            state.article = res.data
        })


        return {
            ...toRefs(state)
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>